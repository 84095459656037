import './index.scss';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from '@weavebio/ui-toolkit';
import { PropsWithChildren, Suspense, useContext, useRef } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { useAuth } from '~/core/contexts/AuthContext';
import UserContext from '~/core/contexts/UserContext';
import { useIsFeatureEnabled } from '~/core/hooks/core/useIsFeatureEnabled';
import { useResizeObserver } from '~/core/hooks/core/useResizeObserver';
import { useStrictParams } from '~/core/hooks/core/useStrictParams';
import { useToast } from '~/core/hooks/core/useToast.tsx';
import { useGetIndApplications } from '~/core/hooks/query/useGetIndApplications';
import { cn } from '~/core/lib/tailwind-util';
import { useGlobalStoreActions } from '~/core/store/global-store';
import {
  useLastOpenedDocumentSectionNumber,
  useLastOpenedTemplateSection,
} from '~/core/store/local-storage-store';
import { useGetSession } from '~/core/store/sessions-store';
import { ScrollToTop } from '~/design-system/components/atoms/ScrollToTop/ScrollToTop';
import { BodyText } from '~/design-system/components/atoms/Text/Body';
import WeaveSpinner from '~/design-system/components/molecules/WeaveSpinner/WeaveSpinner';
import { Support } from '~/design-system/components/organisms/Support';
import { useDashboardData } from '~/features/dashboard/hooks/useDashboardData';
import { useDocument } from '~/features/document-editor/hooks/useDocument';
import { PrivateRoutes } from '~/router/private-routes';
import { PublicRoutes } from '~/router/public-routes';

export const IndLayout = (props: PropsWithChildren) => {
  const params = useStrictParams<{
    indId: string;
    sectionId?: string;
    '*': string;
  }>();

  const { userInds, organizationInds } = useDashboardData();
  const { documentSections } = useDocument({
    indId: params.indId,
    initialDocumentNumber: params.sectionId ?? '',
  });

  const navigate = useNavigate();
  const toast = useToast();
  const { userProfile } = useContext(UserContext);
  const lastOpennedDocumentSectionNumber = useLastOpenedDocumentSectionNumber();
  // @j-weave: I've pulled this into the ind layout for now, but much of this code will get pulled into a provider in the future
  const lastOpenedTemplate = useLastOpenedTemplateSection();

  const { data: indApps } = useGetIndApplications();
  const indApp = indApps?.find((indApp) => indApp.id === params.indId);
  const allowTemplateManagement = useIsFeatureEnabled(
    'fe-allow-template-management',
  );

  const navItemClassName = ({ isActive }: { isActive: boolean }) => {
    return cn(
      'unstyled-a text-weave-primary-200 text-xs font-semibold leading-7 tracking-[2px] uppercase px-2',
      isActive && 'text-weave-primary-600 bg-weave-gray-light rounded-[4px] ',
    );
  };

  const isDocumentLevelSection = documentSections.filter(
    (section) => section.is_document,
  )[0]?.section_number;

  const navigationItems = [
    {
      key: '1',
      label: 'Data Room',
      className: navItemClassName,
      to: `${PrivateRoutes.DATA_ROOM.replace(':indId', params.indId)}`,
    },
    {
      key: '2',
      label: 'Template',
      className: navItemClassName,
      to:
        lastOpenedTemplate ?
          `${PrivateRoutes.TEMPLATE.replace(':indId', params.indId)}?sectionNumber=${lastOpenedTemplate}`
        : `${PrivateRoutes.TEMPLATE.replace(':indId', params.indId)}`,
    },
    {
      key: '3',
      label: 'Ind Manager',
      className: navItemClassName,
      to: `${PrivateRoutes.IND_MANAGER.replace(':indId', params.indId)}`,
    },
    {
      key: '4',
      label: 'Document Editor',
      className: ({ isActive }: { isActive: boolean }) => {
        return cn(
          'unstyled-a text-weave-primary-200 text-xs font-semibold leading-7 tracking-2px uppercase px-2',
          isActive &&
            'text-weave-primary-600 bg-weave-gray-light rounded-[4px]',
          !lastOpennedDocumentSectionNumber && 'opacity-50 pointer-events-none',
        );
      },
      reloadDocument: true,
      to: `${PrivateRoutes.DOCUMENT_EDITOR.replace(
        ':indId',
        params.indId,
      ).replace(
        ':sectionId',
        isDocumentLevelSection ?
          `${isDocumentLevelSection}#${isDocumentLevelSection}`
        : lastOpennedDocumentSectionNumber ?? '',
      )}`,
    },
  ];

  const { logout } = useAuth();

  const dropdownItems = [
    {
      key: '1',
      label: 'Account Settings',
      onClick: () => navigate(PrivateRoutes.PROFILE),
    },
    allowTemplateManagement ?
      {
        key: 'tm',
        label: 'Template Management',
        onClick: () => navigate(PrivateRoutes.MANAGE_TEMPLATES),
      }
    : undefined,
    {
      key: '2',
      label: 'Log Out',
      onClick: async () => {
        // See also DefaultLayout.dropDownItems
        await logout();
        navigate(PublicRoutes.SIGN_IN);
        toast.success({
          message: 'You have been signed out successfully',
        });
      },
    },
  ].filter(Boolean) as Array<{
    key: string;
    label: string;
    onClick: () => void;
  }>;

  const items: MenuProps['items'] = [...userInds, ...organizationInds].map(
    (indApp) => ({
      label: indApp.drugName,
      key: indApp.id,
      onClick: () =>
        navigate(PrivateRoutes.IND_MANAGER.replace(':indId', indApp.id)),
    }),
  );

  const headerRef = useRef<HTMLDivElement>(null);

  const { setHeaderHeight } = useGlobalStoreActions();

  useResizeObserver(headerRef, (entry) => {
    if (!entry) {
      return;
    }

    setHeaderHeight(entry.clientHeight);
  });

  const session = useGetSession();

  return (
    <>
      <ScrollToTop />
      <div className="w-full h-full bg-white">
        <div
          ref={headerRef}
          className="nav w-full fixed py-2 z-10 left-0 top-0 flex items-center justify-between border-b border-solid border-b-weave-primary-400 bg-white mb-[-1x]"
        >
          <div className="flex items-center justify-start">
            <div className="flex items-center justify-center h-full px-4 border-r border-solid border-r-weave-primary-400">
              <Link to={PrivateRoutes.DASHBOARD}>
                <img
                  className="align-middle"
                  alt="weave"
                  width="32px"
                  loading="eager"
                  decoding="sync"
                  src="/images/weave-logo-dark-blue.svg"
                />
              </Link>
            </div>
            <Dropdown menu={{ items: items ?? [] }} trigger={['click']}>
              <div className="relative flex items-center h-full max-w-xs px-8">
                <button className="unstyled-button">
                  <div className="drug-name-container">
                    <BodyText className="font-matter text-xs text-weave-primary-200 font-semibold leading-7 tracking-2px uppercase min-w-[100px] line-clamp-1">
                      {indApp?.drugName ?? ''}
                    </BodyText>
                    <div className="icon-up-down">
                      <UpOutlined as="icon" />
                      <DownOutlined as="icon" />
                    </div>
                  </div>
                </button>
              </div>
            </Dropdown>
            <div className="flex items-center justify-start h-full gap-8 px-12 border-l border-solid border-l-weave-primary-400">
              {navigationItems
                .filter((item) => item !== undefined)
                .map((item) => {
                  return (
                    <NavLink
                      key={item!.key}
                      className={item!.className}
                      to={item!.to}
                      reloadDocument={item!.reloadDocument}
                      end
                    >
                      {item!.label}
                    </NavLink>
                  );
                })}
            </div>
          </div>
          <div className="flex items-center justify-start max-w-md overflow-hidden text-ellipsis whitespace-nowrap">
            <Dropdown
              trigger={['click']}
              className="p-0 border-none outline-none bg-none"
              menu={{
                items: dropdownItems,
              }}
            >
              <button
                type="button"
                className="flex items-center justify-center h-full px-4 border-l border-solid unstyled-button border-l-weave-primary-400"
                onClick={(e) => e.preventDefault()}
              >
                <BodyText
                  size="mini"
                  weight="semibold"
                  color="text-weave-primary"
                >
                  {userProfile.friendlyName !== '' ?
                    userProfile.friendlyName
                  : session?.user?.name ?? 'Menu'}
                </BodyText>
              </button>
            </Dropdown>
          </div>
        </div>
        <div
          className="min-h-screen"
          style={{
            paddingTop: `${headerRef?.current?.offsetHeight}px`,
          }}
        >
          <Support />
          <Suspense
            fallback={
              <WeaveSpinner isFullscreen={true} hasIndicator={true} size="xl" />
            }
          >
            {props.children}
          </Suspense>
        </div>
      </div>
    </>
  );
};
