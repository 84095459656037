import { indApi } from '~/core/api/clients/ind-api';
import { uploadFileToPreSignedUrl } from '~/core/api/services/aws-service';

export const IndEndpoints = {
  IND_APPLICATIONS: 'ind-applications',
  IND_APPLICATION: 'ind-applications/:id',
  IND_APPLICATION_GET_SECTION:
    'ind-applications/section?ind_id=:indId&section_number=:sectionId',
  IND_GENERATE_CONTENT: 'ind-applications/generate-content',
  IND_UPDATE_CONTENT: 'ind-applications/content',
  IND_UPDATE_BLOCK_CONTENT: 'ind-applications/blocks',
  IND_UPDATE_FILE_DOCUMENT_TYPE: 'ind-applications/file',
  IND_SOURCE_FILES: 'ind-applications/:id/files',
  RENAME_FILE: '/ind-applications/files/:fileId/display-name',
  CANCEL_FILE_PROCESSING: '/ind-applications/files/cancel',
  CANCEL_CONTENT_GENERATION: '/ind-applications/cancel-generate-content',
  ADD_NOTE_TO_FILE: '/ind-applications/files/:fileId/note',
  GET_SECTION_SOURCE_FILES:
    '/ind-applications/section/blocks/sources?ind_id=:indId&section_id=:sectionId',
  GET_SECTION_REFERENCES:
    '/ind-applications/section/references?indId=:indId&sectionId=:sectionId',
  GET_DOCUMENT_REFERENCES:
    'ind-applications/:indId/document/:sectionNumber/references',
  GET_DOCUMENT_REFERENCES_REFRESH:
    'ind-applications/:indId/document/:sectionNumber/references/refresh',
  DISCONNECT_SOURCE_FILE: '/ind-applications/disconnect-source-file',
  CONNECT_SOURCE_FILE: '/ind-applications/connect-source-file',
  GET_SECTION_STATUS: '/ind-applications/section-status/:indId/:sectionNumber',
  REGISTER_FILE: '/ind-applications/:indId/files/register',
  REGISTER_FILE_FOR_REPLACE: 'ind-applications/files/register-for-replace',
  GET_PRE_SIGNED_FILE_URL:
    '/ind-applications/:indId/files/pre-signed?fileExtension=:fileExtension',
  GET_MISSING_DATA: '/ind-applications/:indId/source/document/inventory',
  REMOVE_CONTENT:
    '/ind-applications/section/content?indId=:ind_id&sectionNumber=:section_number',
  REFINE_BLOCK_CONTENT: '/ind-applications/block/refine',
  GET_AFFECTED_SECTION_NUMBERS_FOR_DELETION:
    '/ind-applications/:indId/source-files/:fileId/section-numbers',
  DELETE_FILE: '/ind-applications/:indId/source-files/:fileId',
  GET_PRESIGNED_FILE_FOR_REPLACE:
    '/ind-applications/:indId/files/pre-signed-for-replace?fileExtension=:fileExtension&fileId=:fileId',
  SEND_EMAIL: '/ind-applications/send-email',
  GET_SECTIONS_INPUT_COUNT: '/ind-applications/get-sections-input-count',
  EXPORT_DOCUMENT: '/ind/:indId/document/:number/export',
  EXPORT_DOCUMENT_V2: '/ind/:indId/document/:number/export/v2',
} as const;

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const updateIndFileDocumentType = async (
  body: Partial<UpdateIndFileDocumentType>,
) => {
  const path = IndEndpoints.IND_UPDATE_FILE_DOCUMENT_TYPE;
  return indApi.put<UpdateIndFileDocumentType>(path, body);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const generateContent = async (
  body: GenerateIndContentInputModel,
): Promise<GenerateIndContentResponseDto> => {
  return indApi.post<GenerateIndContentResponseDto>(
    IndEndpoints.IND_GENERATE_CONTENT,
    body,
    {},
  );
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const updateSectionContent = async (
  body: UpdateBlockEndpointPayload,
) => {
  const payload = body;

  return indApi.put<{ anyBlockChunkDisconnection: boolean }>(
    IndEndpoints.IND_UPDATE_CONTENT,
    payload,
    {},
  );
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const updateSectionBlockContent = async (body: UpdateBlockPayload[]) => {
  const payload = {
    blocks: body,
  };

  return indApi.put<{ anyBlockChunkDisconnection: boolean }>(
    IndEndpoints.IND_UPDATE_BLOCK_CONTENT,
    payload,
    {},
  );
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const granularUpdateSectionBlockContent = async (
  body: GranularUpdateBlockPayload[],
) => {
  const payload = {
    blocks: body,
  };

  return indApi.put<{ anyBlockChunkDisconnection: boolean }>(
    IndEndpoints.IND_UPDATE_BLOCK_CONTENT,
    payload,
    {},
  );
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const uploadFiles = async (fileObject: IUploadFile) => {
  const promise = new Promise<SourceFile>((resolve, reject) => {
    getPreSignedFileUrl(fileObject.indAppId, fileObject.fileExtension)
      .then((getPreSignedFileUrlResponse) =>
        uploadFileToPreSignedUrl({
          file: fileObject.file,
          preSignedUrl: getPreSignedFileUrlResponse.url,
        })
          .then(() => {
            registerFile({
              id: fileObject.id,
              indAppId: fileObject.indAppId,
              fileName: getPreSignedFileUrlResponse.fileName,
              documentTypeId: fileObject.documentTypeId,
              folderId: fileObject.folderId,
              displayName: fileObject.file.name,
            })
              .then((registeredFile) => resolve(registeredFile))
              .catch(reject);
          })
          .catch(reject),
      )
      .catch(reject);
  });

  return await Promise.resolve(promise);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const moveFile = (payload: MoveFilePayload) => {
  const path = `ind-applications/files/${payload.fileId}/folder`;

  const body: MoveFileBody = {
    folder_id: payload.folder_id,
  };

  return indApi.put(path, body);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const uploadFileForReplace = async (fileObject: IReplaceFile) => {
  const res = await getPreSignedFileForReplace(
    fileObject.indId,
    fileObject.fileExtension,
    fileObject.fileId,
  );
  await uploadFileToPreSignedUrl({
    file: fileObject.file,
    preSignedUrl: res.url,
  });
  registerFileForReplace({ id: fileObject.fileId });
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getPreSignedFileUrl = async (
  indId: string,
  fileExtension: string,
) => {
  const path = IndEndpoints.GET_PRE_SIGNED_FILE_URL.replace(
    ':indId',
    indId,
  ).replace(':fileExtension', fileExtension);

  return indApi.get<IGetPreSignedUrlResponse>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const registerFile = async (body: IRegisterFile) => {
  const endpoint = IndEndpoints.REGISTER_FILE.replace(':indId', body.indAppId);
  return indApi.post<SourceFile>(endpoint, body, {});
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const registerFileForReplace = async (body: { id: string }) => {
  return indApi.post(IndEndpoints.REGISTER_FILE_FOR_REPLACE, body, {});
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getSourceFiles = async (
  indId: string,
  queryParams?: SourceFilesQueryParams,
) => {
  const path = `ind-applications/${indId}/files?folder_id=${
    queryParams?.folder_id ? queryParams.folder_id : 'null'
  }`;

  return indApi.get<SourceFile[]>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getAllSourceFiles = async (
  indId: string,
  includePreviewImageUrl: boolean,
) => {
  if (includePreviewImageUrl === null) includePreviewImageUrl = true;
  const path = `ind-applications/${indId}/files?include_image_preview_url=${includePreviewImageUrl.toString()}`;

  return indApi.get<SourceFile[]>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const renameFile = async (params: RenameParams) => {
  const path = IndEndpoints.RENAME_FILE.replace(':fileId', params.fileId);

  const payload: RenameFilePayload = {
    display_name: params.display_name,
  };

  return indApi.put(path, payload);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const cancelFileProcessing = async (params: CancelProcessingParams) => {
  const path = IndEndpoints.CANCEL_FILE_PROCESSING;
  const payload = {
    id: params.fileId,
  };
  return indApi.post(path, payload);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const cancelContentGeneration = async (
  params: CancelContentGenerationParams,
) => {
  const path = IndEndpoints.CANCEL_CONTENT_GENERATION;
  const payload = {
    ind_id: params.indId,
    section_numbers: params.sectionNumbers,
  };
  return indApi.post(path, payload);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const addFileNote = async (params: AddFileNoteParams) => {
  const path = IndEndpoints.ADD_NOTE_TO_FILE.replace(':fileId', params.fileId);

  const payload: AddNoteToFilePayload = {
    note: params.note,
  };

  return indApi.put(path, payload);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getSectionReferences = async (params: SectionSourcesParams) => {
  const path = IndEndpoints.GET_SECTION_REFERENCES.replace(
    ':indId',
    params.indId,
  ).replace(':sectionId', params.sectionId);

  return await indApi.get<IReference[]>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const connectSourceFile = async (payload: ConnectSourceFileParams) => {
  const path = IndEndpoints.CONNECT_SOURCE_FILE;

  return indApi.put(path, payload);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getSectionStatus = async (indId: string, sectionId: string) => {
  const path = IndEndpoints.GET_SECTION_STATUS.replace(':indId', indId).replace(
    ':sectionNumber',
    sectionId,
  );

  return indApi.get<{ status: DocumentSectionStatus }>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getMissingData = async (indId: string) => {
  const path = IndEndpoints.GET_MISSING_DATA.replace(':indId', indId);

  return indApi.get<IGetMissingDataResponse>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const removeContent = async (indId: string, sectionNumber: string) => {
  const path = IndEndpoints.REMOVE_CONTENT.replace(':ind_id', indId).replace(
    ':section_number',
    sectionNumber,
  );

  return indApi.delete(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const refineContent = async (payload: IRefineBlockContentRequest) => {
  const path = IndEndpoints.REFINE_BLOCK_CONTENT;

  return indApi.put<IRefineBlockContentResponse>(path, payload);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getAffectedSectionNumbersForDeletion = async (
  indId: string,
  fileId: string,
) => {
  const path = IndEndpoints.GET_AFFECTED_SECTION_NUMBERS_FOR_DELETION.replace(
    ':indId',
    indId,
  ).replace(':fileId', fileId);

  return indApi.get<GetAffectedSectionNumbersForDeletion>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const deleteFile = async (indId: string, fileId: string) => {
  const path = IndEndpoints.DELETE_FILE.replace(':fileId', fileId).replace(
    ':indId',
    indId,
  );

  return indApi.delete(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getPreSignedFileForReplace = async (
  indId: string,
  fileExtension: string,
  fileId: string,
) => {
  const path = IndEndpoints.GET_PRESIGNED_FILE_FOR_REPLACE.replace(
    ':indId',
    indId,
  )
    .replace(':fileExtension', fileExtension)
    .replace(':fileId', fileId);

  return indApi.get<IGetPreSignedUrlResponse>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const sendEmail = async (body: ISendEmailRequest) => {
  return indApi.post<{ message_id: string }>(IndEndpoints.SEND_EMAIL, body, {});
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getSectionsInputCount = (body: GetSectionsInputCountPayload) => {
  const path = IndEndpoints.GET_SECTIONS_INPUT_COUNT;

  return indApi.post<GetSectionsInputCountResponse>(path, {
    indId: body.indId,
    ind_id: body.indId,
    section_numbers: body.sectionNumberList,
  });
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getDocumentType = async () => {
  const path = '/ind-applications/files/document_type';
  return indApi.get<DocumentTypeModel[]>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getFiles = async (indId: string) => {
  const path = `ind-applications/${indId}/files?include_image_preview_url=false`;
  return indApi.get<FileModel[]>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getFile = async (indId: string, fileId: string) => {
  const path = `ind-applications/${indId}/files/${fileId}`;
  return indApi.get<{ url: string }>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const exportHtmlToDocx = async (payload: ExportDocumentPayload) => {
  const path = IndEndpoints.EXPORT_DOCUMENT.replace(
    ':indId',
    payload.indId,
  ).replace(':number', payload.number);

  return indApi.post<{ url: string }>(path, {
    html_content: payload.htmlContent,
  });
};

export const exportToDocxV2 = async (payload: ExportDocumentPayloadV2) => {
  const path = IndEndpoints.EXPORT_DOCUMENT_V2.replace(
    ':indId',
    payload.indId,
  ).replace(':number', payload.number);

  return indApi.post<{ url: string }>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const updateSourceFileSummary = async (
  payload: UpdateSourceFileSummaryPayload,
) => {
  const path = `ind-applications/files/${payload.fileId}/tabular-summary`;

  const body: UpdateSourceFileSummaryBody = {
    key: payload.key,
    value: payload.value,
    type: payload.type,
  };

  return indApi.put(path, body);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getRecommendedDocs = async (
  payload: GetRecommendedDocsPayload,
) => {
  const path = `ind-applications/${payload.indId}/section/${payload.sectionNumber}/recommended-files`;

  return indApi.get<RecommendedSourceFile[]>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const createFolder = async (payload: CreateFolderPayload) => {
  const path = `ind-applications/${payload.indId}/folders`;

  const body: CreateFolderBody = {
    name: payload.name,
    parent_folder_id: payload.parent_folder_id ?? null,
  };

  return indApi.post(path, body);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const moveFolder = async (payload: MoveFolderPayload) => {
  const path = `ind-applications/${payload.indId}/folders`;

  const body: MoveFolderBody = {
    id: payload.id,
    name: payload.name,
    parent_folder_id: payload.parent_folder_id,
  };

  return indApi.put(path, body);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const updateFolder = async (payload: UpdateFolderPayload) => {
  const path = `ind-applications/${payload.indId}/folders`;

  const body: UpdateFolderBody = {
    id: payload.id,
    name: payload.name,
    parent_folder_id: payload.parent_folder_id,
  };

  return indApi.put(path, body);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const deleteFolder = async (payload: DeleteFolderPayload) => {
  const path = `ind-applications/${payload.indId}/folders?id=${payload.folder_id}`;

  return indApi.delete(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getSourceFolders = async (
  indId: string,
  parentFolderId?: string | null,
) => {
  const path = `ind-applications/${indId}/folders?parent_folder_id=${
    parentFolderId ?? 'null'
  }`;

  return indApi.get<SourceFolder[]>(path);
};

/**
 * @deprecated - please favor usage of the indHttpAdapter and a service abstraction rather than this usage; the former allows
 * for better unit testability, standardized error handling, centralized logging, and appropriate separation of concerns.  These methods will eventually be
 * converted into the newer format; if you are working on a project that uses them, please take the opportunity to convert them if
 * it's appropriate timing.
 */
export const getAllSourceFolders = async (indId: string) => {
  const path = `ind-applications/${indId}/folders`;

  return indApi.get<SourceFolder[]>(path);
};
